// The IE11 Polyfill needs to be imported first otherwise the app will not render on IE11
import 'react-app-polyfill/ie11';
// tslint:disable-next-line
// import * as Sentry from '@sentry/browser';
import {isIE} from './utils/Supports';

//Sentry.init({ dsn: 'https://fe5ad3c3cae3443485019b634c241864@sentry.io/1494352'});

// We only load core-js for IE (Edge is fine)
if (isIE()) {
    Promise.all([import('core-js'), import('./RenderApp')]).then(
        ([coreJS, RenderApp]) => {
            RenderApp.default();
        },
    );
} else {
    import('./RenderApp').then((RenderApp) => {
        RenderApp.default();
    });
}
